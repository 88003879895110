 
  <!-- Preloader -->
  <div class="loader">
    <!-- Loader inner -->
    <div class="loader-inner">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
    </div>
    <!-- End loader inner -->
  </div>
  <!-- End preloader-->

  <!--Wrapper-->
  <div id="wrapper">

    <!--Home section-->
    <section id="home" class="home">

      <!--Block content-->
      <div class="block-content ">
        <div class="block-teaser block-background-image large overlay" data-background="assets/img/teaser/3.jpg">

          <!--Container-->
          <div class="container ">

            <!--Row-->
            <div class="row">
              <div class="col-sm-12 ">
                <h1 class="white ">Ehtesham Alvi</h1>
                <h3 class="mt-20 white mb-200">UI/UX Developer
                  <br>Front End Developer</h3>

              </div>
            </div>
            <!--End row-->

            <!--Row-->
            <div class="row">
              <div class="col-sm-6 ">
                <a target="_blank" href="http://ehteshamalvi.com/Ehtesham-Alvi.pdf" class="but opc ico white">
                  <i class="icon-print-1"></i>Print Out My CV</a>
              </div>
              <div class="col-sm-6 t-right">
                <p class="white short-email">Do you have any questions ? Write to me at
                  <a href="mailto:ehteshamalvi@gmail.com">ehteshamalvi@gmail.com</a>
                </p>
              </div>
            </div>
            <!--End row-->

          </div>
          <!--End container-->
        </div>
      </div>
      <!--End block content-->

    </section>
    <!--End home section-->

    <!--Navigation container-->
    <div class="nav-container base">
      <nav class="main-nav default">

        <!--Container-->
        <div class="container ">

          <!--Row-->
          <div class="row">
            <div class="mobile-menu-holder">
              <a class="mobile-btn" href="#">
                <i class="icon-menu"></i>
              </a>
            </div>
          </div>
          <!--End row-->
        </div>
        <!--End container-->

        <!--Container-->
        <div class="container ">

          <!--Row-->
          <div class="row">
            <div class="col-sm-5 t-center">
              <ul class="menu first">
                <li>
                  <a class="scroll" href="#home">Home
                    <i class="icon-home"></i>
                  </a>
                </li>
                <li>
                  <a class="scroll" href="#about">About
                    <i class="icon-user"></i>
                  </a>
                </li>
                <li>
                  <a class="scroll" href="#resume">Resume
                    <i class="icon-doc-text-inv"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-2 pd-0 t-center">
              <div class="img-profile">
                <img src="assets/img/testimonial/ea.jpg" alt=""> </div>
              <div class="name-profile">
                <h5>
                  <a class="scroll" href="#home">Ehtesham Alvi
                    <span>UI/ UX Developer</span>
                  </a>
                </h5>
              </div>
            </div>
            <div class="col-sm-5 t-center">
              <ul class="menu second">
                <li>
                  <a class="scroll" href="#portfolio">Portfolio
                    <i class="icon-camera"></i>
                  </a>
                </li>
                <!-- <li>
                  <a class="scroll" href="#blog">Blog
                    <i class="icon-chat"></i>
                  </a>
                </li> -->
                <li>
                  <a class="scroll" href="#contact">Contact
                    <i class="icon-paper-plane"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!--End row-->

        </div>
        <!--End container-->

      </nav>
    </div>
    <!--End navigation container-->

    <!--About section-->
    <section id="about" class="about">

      <!--Block content-->
      <div class="block-content clearfix">
        <div class="block-about one bg-grey-1 ">
          <div class="block-title">
            <h2 class="title">Who am I ?</h2>
          </div>
          <h5 class="lead mt-40">UI/UX Developer and Front End Developer</h5>
          <p class="mt-20">I enjoy being challenged and engaged with projects that require me to work outside knowledge set. Learning new languages and development techniques are important to me.</p>
          <a target="_blank" href="http://ehteshamalvi.com/Ehtesham-Alvi.pdf" class="but brd ico white mt-30">
            <i class="icon-down-circled2 "></i>Download My CV</a>
        </div>
        <div class="block-about two bg-grey-2 ">
          <div class="block-title">
            <h2 class="title">Personal Info</h2>
          </div>
          <ul class="mt-40 info">
            <!-- <li>
              <span>Birthdate</span> : 15/06/1983</li>
            <li> -->
              <li><span>Phone</span> : + 971-54-489-0161</li>
            <li>
              <span>Email</span> : ehteshamalvi@gmail.com</li>
            <li>
              <span>Skype</span> : ehteshamalvi </li>
            <!-- <li><span>Freelance</span> : Available</li>-->
            <li>
              <span>Adresse</span> : Dubai, United Arab Emirates</li>
          </ul>
          <ul class="mt-20 socials">
            <li>
              <a href="http://www.twitter.com/ehteshamalvi">
                <i class="icon-twitter"></i>
              </a>
            </li>
            <li>
              <a href="http://www.facebook.com/ehteshamalvi">
                <i class="icon-facebook"></i>
              </a>
            </li>
            <!-- <li>
              <a href="http://www">
                <i class="icon-gplus"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-pinterest"></i>
              </a>
            </li> -->
            <li>
              <a href="http://www.linkedin.com/in/ehteshamalvi">
                <i class="icon-linkedin"></i>
              </a>
            </li>
            <!-- <li>
              <a href="#">
                <i class="icon-dribbble"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-vimeo"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-flickr"></i>
              </a>
            </li> -->
          </ul>
        </div>
        <div class="block-about three bg-grey-1">
          <div class="block-title">
            <h2 class="title">My Expertise</h2>
          </div>
          <div class="block-expertise mt-40">

            <!--Expertise-->
            <div class="expertise mb-20 clearfix">
              <div class="exp-ico">
                <span class="ico">
                  <i class="ic-desktop"></i>
                </span>
              </div>
              <div class="exp-det">
                <h6>UI/UX Developer</h6>
                <!-- <p>Aliquip ex ea commodo consequat.</p> -->
              </div>
            </div>
            <!--Expertise-->

            <!--Expertise-->
            <div class="expertise mb-20 clearfix">
              <div class="exp-ico">
                <span class="ico">
                  <i class="ic-lock"></i>
                </span>
              </div>
              <div class="exp-det">
                <h6>Front End Developer</h6>
                <!-- <p>Sed do eiusmod tempor incididunt.</p> -->
              </div>
            </div>
            <!--Expertise-->

            <!--Expertise-->
            <div class="expertise clearfix">
              <div class="exp-ico">
                <span class="ico">
                  <i class="ic-phone"></i>
                </span>
              </div>
              <div class="exp-det">
                <h6>Mobile User Interface</h6>
                <!-- <p>Ut enim ad minim veniam exercion.</p> -->
              </div>
            </div>
            <!--Expertise-->

          </div>
        </div>
      </div>
      <!--End block content-->

    </section>
    <!--End about section-->

    <!--Resume section-->
    <section id="resume" class="resume pt-100 pb-80">

      <!--Container-->
      <div class="container ">

        <!--Row-->
        <div class="row">
          <div class="col-md-12">
            <div class="block-title mb-60">
              <h2 class="title">My Resume</h2>
            </div>
          </div>
        </div>
        <!--End row-->

        <!--Row-->
        <div class="row masonry">

          <!--Block content-->
          <div class="block-content">
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-toolbox"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Expertise</h3>
                </div>
                <div class="timeline mt-40">
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>UI/UX Developer &ndash; Unity Infotech (U.A.E)</h4>
                      <h6>Jan 2019 &ndash;
                        <span class="current">Current</span>
                      </h6>
                    </div>
                    <p>
                      Working on Client location named Dutech job role is a UI/UX Developer. Technologies used like HTML, CSS, Bootstrap, Less, JSON, other java frameworks as per requirement.
                      </p>
                  </div>
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>UI/UX Developer &ndash; Mauqah Technology (U.A.E)</h4>
                      <h6>May 2016 &ndash; Jan 2019
                         
                      </h6>
                    </div>
                    <p>Worked on latest technologies like HTML5, CSS3, Bootstrap4, jQuery, JavaScript, Node.js, Angular2+ and other java frameworks as per requirement. </p>
                  </div>
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>UI Expert &ndash; Innovative Solutions (Pakistan)</h4>
                      <h6>Sept 2015 &ndash; May 2016</h6>
                    </div>
                    <p>Developed Social Connecting project for specific users name Eureka which is based on JAVA framework technology
                      by Atlassian. </p>
                  </div>
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>Senior Engineer User Interface  &ndash; AXACT Inc.  (Pakistan)</h4>
                      <h6>Sept 2011 &ndash; June 2015</h6>
                    </div>
                    <p>Developed websites on Wordpress, Blogs and Custom themes Conversion of HTML to different CMS, HTML5, CSS3, Jquery, Bootstrap, Grid, Responsive layouts. Changes on some wordpress Plug-ins as per Clients Requirements, User Interface development of Axact’s Internal ERP, In-House Projects. Seasonal and events base promotions and campaigns.</p>
                  </div>
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>UI/UX Developer &ndash; Dito Tech (Pakistan)</h4>
                      <h6>March 2008 &ndash; June 2011</h6>
                    </div>
                    <p>Joined as a Web Designer and Front End Developer, Designing and HTML of  Clients Websites. Designed logos and worked on some Flash Websites. Enhanced my HTML, CSS, JavaScript/Jquery and PHP Knowledge. Learnt and Started Working on Joomla, Wordpress, Wikihow Opencart. In September 2011 Company Promoted me  as a Team Leader and my Job Description was changed  to clients meeting regarding Projects,  Assigning  work to my Team Members and Report to CEO at the end of the Day. </p>
                  </div>
                  <div class="exp">
                    <div class="hgroup mb-5">
                      <h4>UI/UX Developer &ndash; World E-Merchant (Pakistan)</h4>
                      <h6>Oct 2007 &ndash; March 2008</h6>
                    </div>
                    <p>World E-merchant is a US Based company.
                        My job Description: Design, Develop and maintenance of company`s Internal and External websites. 
                         </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-pencil"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Education</h3>
                </div>
                <div class="timeline mt-40">
                  <div class="edu">
                    <div class="hgroup mb-5">
                      <h4>Master's Degree &ndash; Software Engineering </h4>
                      <h6>Jun 2012 &ndash; June 2014</h6>
                    </div>
                    <p>Master's Degree done from Federal urdu universtiy of Art, Science and Technology (FUUAST). </p>
                  </div>
                  <div class="edu">
                    <div class="hgroup mb-5">
                      <h4>PGD Diploma &ndash; Information Technology </h4>
                      <h6>Jan 2010 &ndash; Dec 2011</h6>
                    </div>
                    <p>Master's Degree done from Federal urdu universtiy of Art, Science and Technology (FUUAST). </p>
                  </div>
                  <div class="edu">
                    <div class="hgroup mb-5">
                      <h4>Bachelors Degree &ndash; Commerce </h4>
                      <h6>2004 &ndash;  2006</h6>
                    </div>
                    <p>Bachelors degree done in commerce from Karachi University. </p>
                  </div>
                  <div class="edu">
                    <div class="hgroup mb-5">
                      <h4>Intermediate  &ndash; Pre-Engineering </h4>
                      <h6>2001 &ndash;  2003</h6>
                    </div>
                    <p>Intermediate done in Science Pre-Engineering from Government Science College. </p>
                  </div>
                  <div class="edu">
                    <div class="hgroup mb-5">
                      <h4>Metriculation &ndash; Science </h4>
                      <h6>2000</h6>
                    </div>
                    <p>Metriculation done in Science from Rose Marry High School </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-tools-2"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Skills</h3>
                </div>
                <div class="skills mt-40 ">
                  <ul class="skill-list clearfix">
                    <li>
                      <h4>HTML5/ CSS3/ Less/ Sass/ Preprocessors</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                    <li>
                      <h4>Jquery/ JavaScript/ jqueryUI</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                      </div>
                    </li>
                    <li>
                      <h4>Bootstrap4/ Foundation/ UI Material & All Frameworks</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                    <li>
                      <h4>Wordress / Joomla/ CMS etc</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                      </div>
                    </li>
                    <li>
                      <h4>Photoshop/ Illustrator</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                      </div>
                    </li>
                    <li>
                      <h4>DreamWeaver/ Visual Studio Code</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                    <li>
                      <h4>Responsive Templates/ Mobile First</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                    <li>
                      <h4>SVN/ Github/ GitBash/ Sourcetree</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                      </div>
                    </li> <li>
                      <h4>NodeJs/ Angular/ NPM </h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                        <span class="transparent"></span>
                        <span class="transparent"></span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-mic"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Languages</h3>
                </div>
                <div class="skills mt-40 ">
                  <ul class="skill-list clearfix">
                    <li>
                      <h4>English</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="transparent"></span>
                      </div>
                    </li>
                    <li>
                      <h4>Urdu</h4>
                      <div class="rating">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                         
                      </div>
                    </li>
                
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-telescope"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Interests</h3>
                </div>
                <div class="hb-list mt-40 ">
                  <ul>
                    <li>
                      <span>
                        <i class="ic-megaphone"></i>
                      </span>
                      <h6>Music</h6>
                    </li>
                    <li>
                      <span>
                        <i class="ic-strategy"></i>
                      </span>
                      <h6>Gaming</h6>
                    </li>
                    <li>
                      <span>
                        <i class="ic-hotairballoon"></i>
                      </span>
                      <h6>Traveling</h6>
                    </li>
                    <li>
                      <span>
                        <i class="ic-basket"></i>
                      </span>
                      <h6>Cooking</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 resume-boxe masonry">
              <div class="inner-resume-boxe">
                <div class="block-icon">
                  <i class="ic-wallet"></i>
                </div>
                <div class="block-title">
                  <h3 class="title medium">Assets</h3>
                </div>
                <div class="ast-list mt-40 ">
                  <ul>
                    <li>
                      <span>Responsible</span>
                      
                      <span>Good Communication</span><span>Self-motivation</span> <span>Creative</span>
                    </li>
                    <li>
                     
                     
                      <span>Working Under Pressure</span><span>Leadership</span> <span>Decision Making</span> <span>Attention to Detail</span>
                    </li>
                    <li>
                    
                     
                      <span>Time Management</span><span>Adaptability</span> <span>Team Player</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--End block content-->

        </div>
        <!--End row-->

        <!--Row-->
        <div class="row">

          <!--Block content-->
          <div class="block-content mt-50  clearfix">
            <ul class="fact-list">
              <li class="col-sm-3">
                <div class="inner-fact clearfix">
                  <span class="ico-fact">
                    <i class="ic-hourglass"></i>
                  </span>
                  <div class="desc-fact">
                    <span class="count">400</span>Project Finished</div>
                </div>
              </li>
              <li class="col-sm-3">
                <div class="inner-fact clearfix">
                  <span class="ico-fact">
                    <i class="ic-trophy"></i>
                  </span>
                  <div class="desc-fact">
                    <span class="count">5</span>Award Winner </div>
                </div>
              </li>
              <li class="col-sm-3">
                <div class="inner-fact clearfix">
                  <span class="ico-fact">
                    <i class="ic-lightbulb"></i>
                  </span>
                  <div class="desc-fact">
                    <span class="count">5623</span>Tea Drinked</div>
                </div>
              </li>
              <li class="col-sm-3">
                <div class="inner-fact clearfix">
                  <span class="ico-fact">
                    <i class="ic-heart"></i>
                  </span>
                  <div class="desc-fact">
                    <span class="count">1400</span>Facebook Likes</div>
                </div>
              </li>
            </ul>
          </div>
          <!--End block content-->

        </div>
        <!--End row-->

      </div>
      <!--End container-->

      <!--Block content-->
       
      <!--End block content-->

      <!--Block content-->
      <div class="block-content clearfix  t-center pt-60">

        <!--Container-->
        <div class="container ">

          <!--Row-->
          <div class="row">
            <div class="block-client clearfix ">
              <ul id="client-carousel">
                <li>
                  <img src="assets/img/logo/l1.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l2.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l3.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l4.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l5.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l6.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l7.png" alt="">
                </li>
                <li>
                  <img src="assets/img/logo/l8.png" alt="">
                </li>
              </ul>
            </div>
          </div>
          <!--End row-->

        </div>
        <!--End container-->

      </div>
      <!--End block content-->

      <!--Block content-->
  
      <!--End block content-->

 

    </section>
    <!--End resume section-->

    <!--Portfolio section-->
    <section id="portfolio" class="portfolio bg-grey  pt-100 pb-50">

      <!--Block content-->
      <div class="block-content">

        <!--Container-->
        <div class="container ">

          <!--Row-->
          <div class="row">
            <div class="col-md-12">
              <div class="block-title mb-60">
                <h2 class="title">Portfolio</h2>
              </div>
            </div>
          </div>
          <!--End row-->

          <!--Block works-->
          <div class="block-works">

            <!--Row-->
            <div class="row">
              
<div class="row">
    <div class="col-sm-4">
        <ul class="list"> 
          <li><a href="http://www.worldlogisticspassport.com" target="_blank"> Worldlogisticspassport.com </a>  </li>
            <li><a href="http://uat.riise.ae" target="_blank"> Uat.riise.ae </a> </li>
            <li><a href="http://demo.ehteshamalvi.com/RootAdmin" target="_blank"> Eureka.com </a> </li>
            <li><a href="http://demo2.ehteshamalvi.com" target="_blank"> EMM.com </a> </li>
            <li><a href="http://thelocationgold.com" target="_blank"> Thelocationgold.com </a> </li>
            <li><a href="https://thelocationuniforms.com/" target="_blank"> Thelocationuniforms.com </a> </li>
            <li><a href="https://muqawil.ehteshamalvi.com/" target="_blank"> Muqawil </a> </li>

            <!--Block filter-->
            <!-- <div class="block-filter mb-50">
        <ul class="filter">
          <li>
            <a class="active" href="#" data-filter="all">All</a>
          </li>
          <li>
            <a href="#" data-filter="web">Web Design</a>
          </li>
          <li>
            <a href="#" data-filter="graphic">Graphic Design</a>
          </li>
          <li>
            <a href="#" data-filter="motion">Motion Graphic</a>
          </li>
        </ul>
      </div> -->
            <!--End block filter-->
            
          
        </ul>
    </div>
    <div class="col-sm-4"> 
      <ul class="list">
        <li><a href="http://dev.ehteshamalvi.com" target="_blank"> Loreal.com </a>  </li> 
        <li><a href="http://www.Thelocationhealth.com" target="_blank"> Thelocationhealth.com </a> </li>
        <li><a href="http://www.dubaiworld.ae" target="_blank"> Dubaiworld.ae </a>  </li>
        <li><a href="http://www.brecorder.com" target="_blank"> Brecorder.com </a>  </li> 
        <li><a href="http://www.hubschool.edu.pk" target="_blank"> Hubschool.edu.pk</a>  </li>
        <li><a href="http://qa1.ehteshamalvi.com" target="_blank"> Qualifications Australia</a>  </li>
         
          
       
      </ul>
  </div>
    <div class="col-sm-4">    
      <ul class="list">
        <li><a href="http://www.riise.ae" target="_blank"> Riise.ae </a>  </li>
        
        <li><a href="http://www.dutech.com" target="_blank"> Dutech.com </a>  </li>
        <li><a href="http://www.customsworld.com" target="_blank"> Customsworld.com </a>  </li>
        <li> <a href="http://www.governorsindh.gov.pk" target="_blank"> Governorsindh.gov.pk</a>  </li>
        <li><a href="http://www.thesocialbond.org" target="_blank"> Thesocialbond.org </a> </li>
        <li><a href="http://qa2.ehteshamalvi.com" target="_blank"> Education site</a>  </li>
      </ul>
    </div>
</div>
            <!--End row-->

            <!--Row-->
            <!-- <div class="row masonry">
              <div class="col-md-3 col-sm-6  masonry item-block">
                <div class="item web">
                  <a href="project_single.html">
                    <div class="desc">
                      <span>in web design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/1.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6  masonry item-block">
                <div class=" item web ">
                  <a href="assets/img/portfolio/2.jpg" class="popup">
                    <div class="desc">
                      <span>in web design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/2.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6  masonry item-block">
                <div class=" item graphic">
                  <a href="assets/img/portfolio/3.jpg" class="popup">
                    <div class="desc">
                      <span>in graphic design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/3.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6  masonry item-block">
                <div class=" item graphic">
                  <a href="assets/img/portfolio/4.jpg" class="popup">
                    <div class="desc graphic">
                      <span>in graphic design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/4.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 masonry item-block">
                <div class=" item graphic">
                  <a href="assets/img/portfolio/5.jpg" class="popup">
                    <div class="desc">
                      <span>in graphic design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/5.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 masonry item-block">
                <div class=" item web">
                  <a href="assets/img/portfolio/6.jpg" class="popup">
                    <div class="desc">
                      <span>in web design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/6.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 masonry item-block">
                <div class=" item motion">
                  <a class="popup-vimeo" href="https://vimeo.com/67429593">
                    <div class="desc">
                      <span>in motion design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/7.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 masonry item-block">
                <div class=" item motion">
                  <a class="popup-youtube" href="https://www.youtube.com/watch?v=gTFljCRnGN4">
                    <div class="desc">
                      <span>in motion design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/9.jpg" alt=""> </a>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 masonry item-block">
                <div class=" item web">
                  <a href="http://www.mutationmedia.net/" target="_blank">
                    <div class="desc">
                      <span>in web design</span>
                      <h5>Project Name</h5>
                    </div>
                    <img src="assets/img/portfolio/10.jpg" alt=""> </a>
                </div>
              </div>
            </div> -->
            <!--End row-->

          </div>
          <!--End block works-->

        </div>
        <!--End container-->

      </div>
      <!--End block content-->
    </div>
    </section>
    <!--End portfolio section-->

 
    <!--Contact section-->
    <section id="contact" class="contact">

      <!--Block content-->
      <div class="block-content">

        <!--Block contact-->
        <div class="block-contact">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.84785662167!2d54.94754379834026!3d25.075707331290918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai!5e0!3m2!1sen!2sae!4v1592987816757!5m2!1sen!2sae" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          <div class="contact-overlay bg-grey-dark" style="display: none;">
            <div class="block-title mb-60">
              <h2 class="title white">Send a Message</h2>
            </div>
            <div class="block-form">
              <form class="contact-form clearfix" method="POST" action="email.php">
             
                <input type="text" placeholder="Name *" value="" id="name" name="name">
                <input type="text" placeholder="Email *" value="" id="email" name="email">
                <input type="text" placeholder="Subject" value="" id="subject" name="subject">
                <textarea name="message" placeholder="Message *" id="message" cols="45" rows="10"></textarea>
                <input type="submit" value="Send Message" class="but opc white">
               
              </form>

              <!--Contact form message-->
              <div id="success">
                <h2>Your message has been sent. Thank you!</h2>
              </div>
              <div id="error">
                <h2>Sorry your message can not be sent.</h2>
              </div>
              <!--End contact form message-->

            </div>
          </div>
        </div>
        <!--End block contact-->

      </div>
      <!--End block content-->

      <!--Block content-->
      <div class="block-content bg-grey ">

        <!--Container-->
        <div class="container">

          <!--Row-->
          <!-- <div class="row">
            <div class="block-info-holder clearfix">
              <div class="col-sm-4">
                <div class="block-info">
                  <div class="info-ico">
                    <span class="ico">
                      <i class="ic-phone"></i>
                    </span>
                  </div>
                  <div class="info-det">
                    <h5 class="mb-10">Call Me</h5>
                    <p>Office : + 123-456-789-456
                      <br> Cell : + 126-156-489-756</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="block-info">
                  <div class="info-ico">
                    <span class="ico">
                      <i class="ic-streetsign"></i>
                    </span>
                  </div>
                  <div class="info-det">
                    <h5 class="mb-10">Address</h5>
                    <p>1234 Street Road
                      <br> City Name, IN 567 890.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="block-info">
                  <div class="info-ico">
                    <span class="ico">
                      <i class="ic-clock"></i>
                    </span>
                  </div>
                  <div class="info-det">
                    <h5 class="mb-10">Work hours</h5>
                    <p> Monday - Friday : 09am - 18pm
                      <br> Saturday : 09am - 1pm </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--End row-->

        </div>
        <!--End container-->

      </div>
      <!--End block content-->
 

    </section>
    <!--End contact section-->

    <!--Footer-->
    <footer class="footer t-center pt-60 pb-60">

      <!--Container-->
      <div class="container">

        <!--Row-->
        <div class="row">
          <div class="col-md-12">
            <ul class="socials round pb-20">
              <li>
                <a href="http://www.facebook.com/ehteshamalvi">
                  <i class="icon-facebook-circled"></i>
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/ehteshamalvi">
                  <i class="icon-twitter-circled"></i>
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/ehteshamalvi">
                  <i class="icon-linkedin-circled"></i>
                  <span>Linkedin</span>
                </a>
              </li>
             
            </ul>
            <hr>
            <p class="pt-20">  
              <a href="http://www.ehteshamalvi.com" target="_blank">Ehtesham Alvi</a> 2020.</p>
          </div>
        </div>
        <!--End row-->

      </div>
      <!--End container-->

    </footer>
    <!--End footer-->

  </div>
  <!--End wrapper-->
 
 